<template>
	<div class="login" :style="{'height':docmHeight + 'px'}" v-if="form.qy_userid">
		<!-- 导航栏 -->
		<!-- <van-nav-bar
			class="nav_bar_color"
			title="登录"
			fixed
			z-index="11" 
			:border="false"
			placeholder
			>
			</van-nav-bar> -->
			<div class="top">
				<img class="imags" src="@/assets/icon_topbj.png" alt="">
				<div class="title">凯迪拉克销售助手</div>
				<div class="version">V2.4.0</div>
			</div>
			<div class="input_mobile">
				<input type="text" maxlength="11"  placeholder-class="plainput" placeholder="输入手机号" v-model="form.mobile">
			</div>
			<div class="input_captcha">
				<input type="text" maxlength="4" placeholder="输入验证码" v-model="form.captcha">
				<span @click="getcaptcha" class="captcha">{{iscaptcha==0?'获取验证码':iscaptcha}}</span>
			</div>
			<div class="user">
				<img @click="isagreement=!isagreement" v-if="!isagreement" src="@/assets/icon_xz.png" alt="">
				<img @click="isagreement=!isagreement" v-if="isagreement" src="@/assets/icon_wxz.png" alt="">
				<div>已阅读并同意<a @click="gouser">用户协议</a></div>
				<!-- 和<a>隐私政策</a> -->
			</div>

			<div :class="{'iscolor':this.form.mobile!='' && this.form.captcha!='' && this.form.qy_userid!=''}" class="upload" @click="mobilelogin">登录</div>
	</div>
</template>
<script>
export default {
	data() {
		return{
			info:{},
			form:{
				mobile:'',
				qy_userid:'',
				captcha:''
			},
			iscaptcha:0,
			setout:null,
			isagreement:false,
		}
	},
	computed: {    
    docmHeight() {
			console.log(this.$store.state.height)
      return this.$store.state.height;
    }
  },
	mounted(){
		localStorage.removeItem('accessToken')
		const accessToken = localStorage.getItem('accessToken')
		if(accessToken){
			return this.$router.push({path:'/home'})
		}
		console.log(JSON.stringify({})  == JSON.stringify({}))
		let loginform = localStorage.getItem('loginform')
		if(loginform != null && loginform != ''){
			loginform = JSON.parse(loginform)
			this.form = loginform
			return localStorage.removeItem('loginform')
		}
		const code = this.$route.query.code
		const url1 = localStorage.getItem('url') + '/#/login'
		const url2 = localStorage.getItem('ur2') + '/#/login'
		const url = window.location.href
		if(code != null && code){
			return this.getcode(code)
		}
		if(url1 == url){
			this.getcordid()
		}else{
			// alert(url)
			// alert(url.slice(url.indexOf('code'),url.indexOf('/#/login')))
			if(url.indexOf('https://td-h5.1des1.com.cn') != -1){
				location.href = 'https://td-h5.1des1.com.cn/#/login?'+url.slice(url.indexOf('code'),url.indexOf('&state=STATE'))
			}else{
				location.href = 'https://td-h5-test.1des1.com.cn/#/login?'+url.slice(url.indexOf('code'),url.indexOf('&state=STATE'))
			}
			
		}
	},
	methods:{
		// 获取验证码
		async getcaptcha(){
			if(this.setout != null) return ''
			if(this.form.mobile.length<7 ||  this.form.mobile.length>11) return this.$toast.fail('请输入正确手机号')
			const {data:res} = await this.$http.post('/api/user/login_sms_send_2',{mobile:this.form.mobile})
			if(res.code == 1){
				this.$toast.success('验证码发送成功')
				this.iscaptcha = 60
				this.setout = setInterval(()=> {
					
					this.iscaptcha -- 
					if(this.iscaptcha == 0){
						clearInterval(this.setout)
						this.setout = null
					}
				},1000)
			}else{
				this.$toast.fail('获取验证码失败')
			}
		},

		// 用户协议
		gouser(){
			const url = localStorage.getItem('share')
			localStorage.setItem('loginform',JSON.stringify(this.form))
			window.location.href = url + '/scsj/scsjxkxy/'
		},

		// 获取code
		async getcode(code) {
			const {data:res} = await this.$http.post('/api/wechat/get_qy_wechat_user_info',{name:'凯迪拉克',code:code})
			
			if(res.code == 1){
				// alert(JSON.stringify(res)) 
				if(res.data.res.userinfo != null && JSON.stringify(res.data.res?.userinfo) != JSON.stringify([])){
					this.$toast.success('登录成功')
					localStorage.setItem('accessToken',res.data.res.userinfo?.token)
					localStorage.setItem('scsjStoreId',res.data.res.userinfo?.scsj_store_id)
					this.$router.push({path:'/home'})
				}else{ 
					this.form.qy_userid = res.data.res.user_id
				}
				
			}else{
				this.$toast.fail(res.msg)
			}
		},

		// 获取cordid
		async getcordid() {
			const {data:res} = await this.$http.post('/api/wechat/get_qy_wechat_corp_info',{name:'凯迪拉克'})
			if(res.code == 1){
				const info = res.data.res
				
				const url = encodeURIComponent(window.location.href)
				 location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.corp_id}&redirect_uri=${url}&response_type=code&scope=snsapi_base&agentid=${info.agent_id}&state=STATE#wechat_redirect`
				
			}else{
				this.$toast.fail(res.msg)
			}
		},
		// dp号登录
		async login(dp) {
			const {data:res} = await this.$http.post('/api/user/applet_mobile_login_2',{dpname:dp})
			if(res.code == 1){
				this.$toast.success('登录成功')
				localStorage.setItem('accessToken',res.data.res.userinfo.token)
				localStorage.setItem('scsjStoreId',res.data.res.userinfo.scsj_store_id)
				this.$router.push({path:'/home'})
			}else{
				this.$toast.fail(res.msg)
			}
		},
		// 手机号登录
		async mobilelogin() {
			console.log(this.form)
			if(!(this.form.mobile && this.form.captcha && this.form.qy_userid)) return ''
			if(!this.isagreement) return this.$toast.fail('请勾选用户协议')
			const {data:res} = await this.$http.post('/api/user/applet_mobile_login_3',this.form)
			if(res.code == 1){
				this.$toast.success('登录成功')
				localStorage.setItem('accessToken',res.data.res.userinfo.token)
				localStorage.setItem('scsjStoreId',res.data.res.userinfo.scsj_store_id)
				this.$router.push({path:'/home'})
			}else{
				this.$toast.fail(res.msg)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.login{
	width: 750px;
	padding: 0 40px;
	box-sizing: border-box;
	background: #FFFFFF;
	padding-top: 200px;
	.top{
		padding: 100px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.imags{
			width: 120px;
			height: 120px;
		}
		.title{
			margin-top: 40px;
			height: 54px;
			font-size: 48px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #333333;
			line-height: 54px;
		}
		.version{
			height: 34px;
			margin-top: 6px;
			font-size: 28px;
			font-family: HYQiHeiY3-55;
			font-weight: 400;
			color: #999999;
			line-height: 34px;
		}
	}
	.input_mobile{
		input{
			width: 670px;
			height: 92px;
			border-radius: 4px;
			box-sizing: border-box;
			font-family: HYQiHeiY3-55;
			font-size: 32px;
			font-weight: 400;
			color: #333333;
			padding: 0 40px;
			border: 1px solid #D2D2D2;
		}
	}
	.input_captcha{
		margin-top: 40px;
		position: relative;
		input{
			font-size: 32px;
			font-weight: 400;
			color: #333333;
			width: 670px;
			height: 92px;
			border-radius: 4px;
			box-sizing: border-box;
			font-family: HYQiHeiY3-55;
			padding: 0 40px;
			border: 1px solid #D2D2D2;
		}
		.captcha{
			position: absolute;
			top: 28px;
			right: 40px;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #999999;
			line-height: 40px;
		}
	}
	.user{
		margin-top: 40px;
		display: flex;
		align-items: center;
		img{
			width: 28px;
			height: 28px;
		}
		div{
			margin-left: 10px;
			font-size: 28px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #999999;
			line-height: 34px;
			a{
				color: #000;
				border-bottom: 3px;
			}
		}
	}
	.upload{
		margin-top: 160px;
		width: 100%;
		height: 88px;
		background: #C5C5C5;
		border-radius: 4px;
		line-height: 88px;
		font-size: 36px;
		text-align: center;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.plainput{
	font-size: 32px;
	font-family: HYQiHeiY3-55;
	font-weight: normal;
	color: #999999;
}

.iscolor{
	background: #FA0037 !important;
}
</style>